section#tabbed-content {
  width:100%;
  padding:0;

  .tabs {
    display: none;

    a {
      background-color: $grey2;

      &:hover,
      &.selected {
        background-color: $grey1;

      }
    }

  }

  .content {

    > a {
      background-color: $grey1;
      color: $white;
      display: block;
      font-weight: bold;
      height:auto;
      line-height: 18px;
      padding: $gap2;
      text-transform: uppercase;

    }

    .tab-content {
      background-color: $grey1;
      display: none;
      margin: 0 0 20px 0;
      padding:$gap3;
      position: relative;

      &.selected {
        display: block;

      }

      ol {
        position: relative;
        li::before {
          color: $white;

        }

      }
      ul, p, h2, h3, h4, h5 {
        position: relative;
      }

      a {
        font-weight: 700;

      }

    }

  }

  &.orange {

    .tabs {

      a {
        background-color: $orange;

        &:hover,
        &.selected {
          background-color: $darkOrange;

        }
      }

    }

    .content {
      > a {
        background-color: $darkOrange;

      }

      .tab-content {
        background-color: $darkOrange;

      }

    }

  }

  &.blue {

    .tabs {

      a {
        background-color: $blue;

        &:hover,
        &.selected {
          background-color: $darkBlue;

        }

      }

    }

    .content {
      > a {
        background-color: $darkBlue;

      }

      .tab-content {
        background-color: $blue;

      }

    }

  }


  @media (min-width: $medium){

    .tabs {
      @include flex-row(stretch, stretch);
      margin: $gap3 0 0 0;

      a {
        border-bottom: 1px solid $grey1;
        border-right: 1px solid $grey1;
        color: $white;
        display: block;
        flex: 1 1 33%;
        font-weight: bold;
        height: 95px;
        padding: 12px $gap2;
        text-transform: uppercase;
        transition: background-color 300ms;

        &.selected {
          border-bottom: 1px solid transparent;

        }

        &:last-of-type {
          border-right:none;

        }

      }

    }

    .content {

      > a {
        display: none;

      }

      .tab-content {
        background-color: $grey1;
        margin: 0;
        padding:$gap5;

      }

    }



    &.orange {

      .content {

        .tab-content {
          background-color: $darkOrange;
          background-image: url('/assets/images/sprites/orange-tab-footer.svg');
          background-position: bottom center;
          background-repeat: no-repeat;

          &:before {
            background: -moz-linear-gradient(top, rgba($darkOrange, 1) 0%, rgba($darkOrange, 0.7) 100%);
            background: -webkit-linear-gradient(top, rgba($darkOrange, 1) 0%,rgba($darkOrange, 0.7) 100%);
            background: linear-gradient(to bottom, rgba($darkOrange, 1) 0%,rgba($darkOrange, 0.7) 100%);
            bottom: 0;
            content: "";
            height: 90px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 0;

          }

        }

      }

    }

    &.blue {

      .content {

        .tab-content {
          background-color: $darkBlue;
          background-image: url('/assets/images/sprites/blue-tab-footer.svg');
          background-position: bottom center;
          background-repeat: no-repeat;

          &:before {
            background: -moz-linear-gradient(top, rgba($darkBlue, 1) 0%, rgba($darkBlue, 0.7) 100%);
            background: -webkit-linear-gradient(top, rgba($darkBlue, 1) 0%,rgba($darkBlue, 0.7) 100%);
            background: linear-gradient(to bottom, rgba($darkBlue, 1) 0%,rgba($darkBlue, 0.7) 100%);
            bottom: 0;
            content: "";
            height: 90px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 0;

          }

        }

      }

    }

  }

  @media(min-width: $max){

    .tabs {

      a {
        line-height: 22px;
        padding: $gap2;

      }

    }

  }

}
