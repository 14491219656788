section#cta-blocks {
  @include flex-column(space-between, stretch);
  background-color: transparent;

  > a {
    margin: 0 0 $gap2 0;
    padding: $gap4 $gap6 $gap4 $gap4;
    position: relative;
    transition: background-color 300ms;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;

    }

    > svg {
      @include size(35);
      bottom: 20px;
      fill: $white;
      opacity: 0;
      position: absolute;
      right: -20px;
      transition: $transition-timing;

    }

    h3 {
      @extend .regular;
      font-size: 23px;
      letter-spacing: 0.2em;
      line-height: 30px;
      margin: 0 0 $gap5 0;
      position: relative;
      text-transform: uppercase;

    }

    p {
      position: relative;
      margin: 0;

    }

    &:hover {

      svg {
        right: 20px;
        opacity: 1;

      }

    }

    &.orange {
      background-color: $orange;
      background-image: url('/assets/images/sprites/claims-block.svg');
      background-repeat: no-repeat;
      background-size: cover;

      &:hover{
        background-color: darken($orange, 5%);

      }

      &:before {
        background: -moz-linear-gradient(-45deg, rgba($orange, 0) 0%, rgba($orange, 1) 70%);
        background: -webkit-linear-gradient(-45deg, rgba($orange, 0) 0%,rgba($orange, 1) 70%);
        background: linear-gradient(135deg, rgba($orange, 0) 0%,rgba($orange, 1) 70%);

      }

    }

    &.blue {
      background-color: $blue;
      background-image: url('/assets/images/sprites/tax-relief-block.svg');
      background-repeat: no-repeat;
      background-size: cover;

      &:hover{
        background-color: lighten($blue, 5%);

      }

      &:before {
        background: -moz-linear-gradient(-45deg, rgba($blue, 0) 0%, rgba($blue, 1) 70%);
        background: -webkit-linear-gradient(-45deg, rgba($blue, 0) 0%,rgba($blue, 1) 70%);
        background: linear-gradient(135deg, rgba($blue, 0) 0%,rgba($blue, 1) 70%);

      }

    }

    &.purple {
      background-color: $purple;
      background-image: url('/assets/images/sprites/contact-block.svg');
      background-repeat: no-repeat;
      background-size: cover;

      &:hover{
        background-color: lighten($purple, 5%);

      }

      &:before {
        background: -moz-linear-gradient(-45deg, rgba($purple, 0) 0%, rgba($purple, 1) 70%);
        background: -webkit-linear-gradient(-45deg, rgba($purple, 0) 0%,rgba($purple, 1) 70%);
        background: linear-gradient(135deg, rgba($purple, 0) 0%,rgba($purple, 1) 70%);

      }

    }

    &:first-of-type {
      margin-left: 0;

    }

    &:last-of-type {
      margin-right: 0;

    }

  }

  @media(min-width: $large) {

    @include flex-row(space-between, stretch);

    > a{
      flex: 1 0 30%;
      margin: 0 $gap;
      padding: $gap2;

    }

  }

  @media(min-width: $max) {

    @include flex-row(space-between, stretch);

    > a{
      margin: 0 $gap3;
      padding: $gap4;

    }

  }

}
