body#about-us {

  .our-story {

    strong {
      color: $primary;

    }

  }

  .main-profiles {

    figure {
      margin: 0 0 $gap4 0;

      svg {
        @include size(20);
        fill: $primary;

      }

    }

    .button {
      margin-right: $gap4;
    }

    .linkedin {
      color: #999;
      font-size: 13px;

      svg {
        margin: 0 3px 0 0;
        transform: translateY(2px);

      }

      &:hover {
        color: $primary;

      }

    }

  }

  .sub-profiles {

    position: relative;

    h5 {
      margin-bottom: $gap;

    }

    img {
      width: 100%;

    }

  }

  #tabbed-content {

    h3 {
      color: $secondary;
      margin: $gap2  0;
    }

    h5 {
      @extend .bold;
      font-size: 16px;
      line-height: 30px;

    }

    .content {

      .tab-content {
        background-image: url('/assets/images/sprites/about.svg');
        background-position: bottom center;
        background-repeat: no-repeat;

        &:before {
          background: -moz-linear-gradient(left, rgba($grey1, 1) 0%, rgba($grey1, 0) 100%);
          background: -webkit-linear-gradient(left, rgba($grey1, 1) 0%,rgba($grey1, 0) 100%);
          background: linear-gradient(to right, rgba($grey1, 1) 0%,rgba($grey1, 0) 100%);
          bottom: 0;
          content: "";
          height: 300px;
          left: 0;
          position: absolute;
          width: 100%;
          z-index: 0;

        }

      }

    }

  }


  @media(max-width: $medium - 1px ){

    .linkedin{
      display: block;
      margin-top: $gap2;

    }

  }

  @media(min-width: $large) {

    .main-profiles{
      @include flex-row(flex-start, flex-start);

      figure {
        flex: 1 1 50%;

        &:first-of-type{
          padding:0 $gap2 0 0;

        }

        &:last-of-type{
          padding:0 0 0 $gap2;

        }

        img {
          width: 100%;

        }

      }

    }

    .sub-profiles {
      @include flex-row(flex-start, flex-start);
      flex-wrap: wrap;

      > div {
        flex: 0 1 50%;
        padding:0 $gap2 0 0;
        z-index: 1;

        &:nth-of-type(2n){
          padding:0 0 0 $gap2;

        }

      }

    }

  }

}
