section#process-carousel {

  padding: 70px 0;
  text-align: center;

  h3 {
    @extend .regular;
    font-size: 23px;
    letter-spacing: 0.2em;
    line-height: 30px;
  }

  > p {
    margin: 0 $gap2 $gap3 $gap2;

  }

  process-carousel {
    @include flex-row(center, flex-start);
    height: 270px;
    overflow: hidden;
    position: relative;
    width: 100%;

    a.carousel-item {
      display: block;
      position: absolute;
      text-align: center;
      width: 50%;
      left: 25%;
      top:20px;
      transition: 600ms;
      transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
      transform: translate3d(0px, 0px, 0) scale(1);
      opacity: 0;
      pointer-events: none;

      &[data-index="8"]{ transform: translate3d(-500px, 0px, 0)   scale(0.2); opacity: 0 }
      &[data-index="9"]{ transform: translate3d(-300px, 0px, 0)   scale(0.5); opacity: 0.4 }
      &[data-index="10"]{ transform: translate3d(-160px, 0px, 0)  scale(0.8); opacity: 0.7 }

      &[data-index="1"]{ transform: translate3d(0px, 0px, 0)     scale(1);   opacity: 1;

        // TODO: Uncomment when linkable navigation
        // pointer-events: all;
      }

      &[data-index="2"]{ transform: translate3d(160px, 0px, 0)   scale(0.8); opacity: 0.7 }
      &[data-index="3"]{ transform: translate3d(300px, 0px, 0)   scale(0.5); opacity: 0.4 }
      &[data-index="4"]{ transform: translate3d(500px, 0px, 0)   scale(0.2); opacity: 0 }

      span {
        @include size(120);
        background: $blue;
        border-radius: 50%;
        display: inline-block;
        padding: 10px;
        transform:rotate(-45deg);
        transition: 700ms;

      }

      &:nth-of-type(2n){
        span {
          background: $orange;

        }
        p:first-of-type{
          color: $orange;
        }
      }

      &[data-index="1"]{

        span {
          @include size(120);
          border-radius: 50% 50% 50% 0;
        }

        svg {
          fill: $white;

        }

      }

      p{
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        &:first-of-type(){
          @extend .bold;
          margin: 35px 0 0 0;
          color: $blue;

        }

        &:last-of-type(){
          display: inline-block;
          width:180px;

        }

      }

      svg {
        @include size(70);
        margin: 15px;
        transform: rotate(45deg);
        fill: $grey1;
        transition: fill 500ms;

      }

    }

  }

  carousel-nav {
    @include flex-row(space-between, center);
    position: absolute;
    width: 100%;
    top: 31%;
    padding: 0;

    button {
      cursor: pointer;
      padding: $gap;

      svg {
        @include size(20);
        fill: $white;

      }

    }

  }


  @media(min-width: $medium) {

    carousel-nav {
      top: 39%;
      padding: 0 $gap4;

    }

  }

  @media(min-width: $max) {

    carousel-nav {
      padding: 0 $gap8;

    }

    > p {
      padding: 0 150px;

    }

  }

}
