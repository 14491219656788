section#management {

  padding-bottom: $gap2;
  padding-top: 0;

  > div {
    @include flex-row();
    background-color: $purple;
    background-image: url('/assets/images/sprites/contact-block.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
    padding: $gap3;
    position: relative;

    &:before {
      background: -moz-linear-gradient(-45deg, rgba($purple, 0) 0%, rgba($purple, 1) 70%);
      background: -webkit-linear-gradient(-45deg, rgba($purple, 0) 0%,rgba($purple, 1) 70%);
      background: linear-gradient(135deg, rgba($purple, 0) 0%,rgba($purple, 1) 70%);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

    }

    &:first-of-type {
      margin: 0 0 $gap4 0;

    }

    > div {
      position: relative;

      &.image {
        flex: 0 1 130px;

        img {
          border-radius: 50%;

        }

      }

      h4{
        text-transform: uppercase;

      }

      p {
        margin: 0;
        line-height: 30px;

        span {
          color: $orange;
          display: inline-block;
          width: 20px;
        }

      }

      a {

        &:last-of-type {
          font-size: 12px;

        }

        svg {
          @include size(20);
          fill: $blue;
          margin: 0 $gap 0 0;
          transform: translateY(1px);

        }

      }

      .button {
        background-color: darken($purple, 5%);
        margin: $gap2 0 $gap2 0;

        &:hover {
          background-color: $purple;

        }

      }

    }

  }

  @media(min-width: $large ) {

    @include flex-row();
    padding-bottom: $gap4;

    > div {
      flex: 1 1 50%;
      padding: $gap4;

      &:first-of-type {
        margin: 0 $gap3 0 0;

      }

      &:last-of-type {
        margin: 0 0 0 $gap3;

      }

    }

  }

}
