section#call-us {

  text-align: center;
  padding-top: 0;
  padding-bottom: 0;

  p{
    font-size: 25px;
    line-height: 40px;
    margin: 0;

  }

  h4 {
    color: $purple;
    font-size: 25px;
    line-height: 40px;

  }


}
