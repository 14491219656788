@mixin size($size){
  width:$size + px;
  height:$size + px;
}

@mixin flex-column($justify: flex-start, $align:stretch){
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin flex-row($justify: flex-start, $align:stretch){
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-align-items: $align;
  align-items: $align;
}
