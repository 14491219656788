section#about-us {

  background-color: $grey1;
  background-image: url('/assets/images/about-us-background.png');
  background-repeat: no-repeat;
  background-position: bottom right;

  @media(min-width: $medium) {

    height: 220px;
    margin: $gap3 0 0 0;
    padding: 0 $gap5;

    @include flex-row(space-between, center);

    p{
      max-width: 40%;
      text-align: right;

    }

    div{
      position: relative;
      width: 348px;
      height: 100%;

      img{
        bottom:0;
        position: absolute;
        left: 0;

      }

    }

  }

}
