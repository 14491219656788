////////////////////////////////////////////////////////////////////////////////
// General Settings
////////////////////////////////////////////////////////////////////////////////

// =============================================================================
// Colours
// =============================================================================

$orange       : #f7941d;
$blue         : #0094cc;
$purple       : #855678;

$white        : #FFFFFF;

$grey1        : #444444;
$grey2        : #4e4e4e;
$grey3        : #575757;

$darkOrange   : #a2733c;
$darkBlue     : #057caa;

$red          : #fb3d3d;

$selection    : $orange;
$text-colour  : $white;

// =============================================================================
// Units
// =============================================================================

$border-radius      : 6;
$font-size          : 15;
$spacing            : 3;
$transition-timing  : 250ms;


// =============================================================================
// Gaps
// =============================================================================

$_gap         : 8;

$gap          : $_gap * 1px;
$gap2         : $_gap * 2px;
$gap3         : $_gap * 3px;
$gap4         : $_gap * 4px;
$gap5         : $_gap * 5px;
$gap6         : $_gap * 6px;
$gap7         : $_gap * 7px;
$gap8         : $_gap * 8px;


// =============================================================================
// Breakpoints
// =============================================================================

$min           : 320px;
$small         : 480px;
$medium        : 800px;
$large         : 970px;
$max           : 1200px;

// =============================================================================
// Fonts
// =============================================================================

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

%font-smooth {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.regular, .default { font-family:"Montserrat", Helvetica, sans-serif; font-weight: 400; @extend %font-smooth; }
.bold  { font-family:"Montserrat", Helvetica, sans-serif; font-weight: 700; @extend %font-smooth; }
