section#form-callback{

  background-color: transparent;
  padding: $gap2;

  form {
    background-color: $grey1;
    padding: $gap2;

    h3 {
      font-weight: bold;

      svg {
        @include size(22);
        fill: $purple;
        margin: 0 $gap 0 0;
        transform: translateY(2px);

      }

    }

    .form-wrapper {
      @include flex-column();

      > div {

        .button {
          float: right;
          margin: $gap 0 0 0;

        }

      }

      small {
        color: $red;

      }

    }

  }

  @media(min-width: $medium){

    padding: $gap4 $gap6;

    form{
      padding: $gap3;
      width: 100%;

      .form-wrapper {
        @include flex-row();

        > div {
          flex: 1 1 50%;
          margin: 0 $gap5 0 0;

          &:last-of-type{
            margin: 0;

          }

        }

      }

    }

  }

  @media(min-width: $max){

    padding: 0 $gap8 $gap4 $gap8;

  }

}
