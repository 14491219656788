$primary: #0094cc; $secondary: #f7941d; $fonts: '/assets/fonts/'; $images: '/assets/images/';@import 'settings', 'mixins';////////////////////////////////////////////////////////////////////////////////
// Global Styling
////////////////////////////////////////////////////////////////////////////////

*{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;

}

body {
  @extend .default;
  background-color: $grey2;
  color: $text-colour;
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;

  site-wrapper {

  }

  @media(min-width: $medium) {

    site-wrapper {
      @include flex-row(flex-start, stretch);

    }

  }

}

h1 {
  color: $text-colour;
  font-size: 28px;
  font-weight: bold;
  line-height: 46px;
  margin-bottom: $gap;


}

h2, h3, h4, h5, h6 {
  color: $text-colour;
  line-height: 1;
  margin-bottom: $gap3;

}

h2{
  font-size: 24px;
  font-weight: normal;
  line-height: 34px;

}

h3 {
  @extend .regular;
  text-transform: uppercase;

}

h5 {
  @extend .regular;
  font-size: 24px;
  line-height: 34px;

}

p {
  color: $text-colour;
  margin: 0 0 $gap2 0;

}

a {
  color: $white;
  text-decoration: none;
  transition: color $transition-timing;

  &:hover {
    color: $blue;

  }

}

// FORMS - - -

input[type="text"],
input[type="email"],
textarea {
  @extend .default;
  background-color: $grey2;
  border: none;
  color: $white;
  margin: $gap 0;
  outline: none;
  padding: $gap2 $gap2;
  width:100%;

  &::-webkit-input-placeholder { text-transform: uppercase; }
  &::-moz-placeholder { text-transform: uppercase; }
  &:-ms-input-placeholder { text-transform: uppercase; }
  &:-moz-placeholder { text-transform: uppercase; }

}

select {
  @extend .default;
  appearance: none;
  background-color: $grey2;
  border: none;
  border-radius: 0;
  color: $white;
  margin: $gap 0;
  outline: none;
  padding: $gap2 $gap2;
  width:100%;
}

button-group{
  display: block;
  width: 100%;

  > * {
    margin: 0 5px 0 0;
  }

  > :last-child {
    margin: 0 0 0 5px;
  }

  &.centered{
    text-align: center;

  }

}

.button{
  background-color: $purple;
  border: none;
  color: $white;
  display: inline-block;
  font-size: 15px;
  letter-spacing: 0.2em;
  padding: $gap $gap2;
  text-transform: uppercase;
  transition: background-color $transition-timing;

  &:hover {
    background-color: darken($purple, 10%);
    color: $white;

  }

}

.backlink{
  color: #999;
  font-size: 12px;
  margin-bottom: $gap3;

}


content {
  display: block;
  padding: $gap3 $gap2;

  ol {
    counter-reset: li;
    list-style: none;
    margin: 0 0 $gap2 0;

    li::before {
      content: counter(li) ")";
      color: $orange;
      display: inline-block; width: 1em;


    }

    li {
      counter-increment: li;
      padding: 2px 0;
    }

  }

  ul {
    margin: 0 0 $gap2 $gap2;

  }

}

section {
  display: block;
  padding: $gap4 $gap2;
  width: 100%;

}


@media(min-width: $medium){

  h1{
    max-width: 80%;
    font-size: 35px;
    margin-bottom: 87px;
  }

  content {
    @include flex-column(flex-start, flex-start);
    margin: 125px 0 0 0;
    padding: $gap6 $gap6;

    ol,
    ul {
      margin: 0 0 $gap2 $gap4;

    }

  }

  section {
    padding: $gap6 $gap6;

  }

}


@media(min-width: $max){


  content {
    padding: $gap6 $gap8;

  }

  section {
    padding: $gap8;

  }

}
@import 'src/sass/layouts', 'src/sass/mixins', 'src/sass/aos/animations', 'src/sass/aos/core', 'src/sass/aos/easing', 'src/sass/components/about-us', 'src/sass/components/call-us', 'src/sass/components/cta-blocks', 'src/sass/components/heydon-insights', 'src/sass/components/latest-news', 'src/sass/components/management', 'src/sass/components/process-carousel', 'src/sass/components/process-scrollable', 'src/sass/components/tabbed-content', 'src/sass/components/tabbed-links', 'src/sass/components/testimonial', 'src/sass/forms/callback', 'src/sass/forms/contact', 'src/sass/pages/about-us', 'src/sass/pages/error', 'src/sass/pages/holding', 'src/sass/pages/home', 'src/sass/pages/news', 'src/sass/structure/header', 'src/sass/structure/main', 'src/sass/structure/navigation';