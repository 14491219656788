body#home {

  content {

    h1{
      font-weight: normal;
      margin-bottom: 30px;
      
    }

    h2 {
      @extend .bold;
      color: $orange;
      margin-bottom: 30px;

    }

  }

}
