section#latest-news {

  background: $grey1;

  h3 {
    @extend .regular;
    margin: 0 0 $gap5 0;
    font-size: 23px;
    letter-spacing: 0.2em;
    margin-bottom: $gap5;
    text-align: center;
    text-transform: uppercase;

  }

  h4 {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;

  }

  p{
    font-size: 13px;
    line-height: 20px;
    margin-bottom: $gap2;
  }

  a {
    @extend .bold;
    color: $purple;
    font-size: 14px;


  }

  date {
    display: block;
    color: $blue;
    font-size: 15px;
    margin: 0 0 $gap 0;
  }

  @media(min-width: $medium) {

    > div {
      @include flex-row(center center);

      > div {
        flex: 1 1 33%;
        padding:0 $gap3;
        border-right: 1px solid rgba($white, 0.15);

        &:last-of-type{
          border-right: none;

        }

      }

    }

  }

}
