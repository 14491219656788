section#heydon-insights {
  background-color: $grey1;
  text-align: center;
  padding-top:70px;
  padding-bottom:70px;

  svg {
    @include size(50);
    fill: $orange;
    margin: $gap2 0 0 0;

  }

  h3 {
    @extend .regular;
    margin: 0 0 $gap5 0;
    font-size: 23px;
    letter-spacing: 0.2em;
    line-height: 30px;
    text-transform: uppercase;

  }

  @media(min-width: $medium) {

    svg {
      margin: 0;

    }

    .flexwrapper {
      @include flex-row(center center);
      margin: $gap2 0 $gap4 0;

      > div {
        border-right: 1px solid rgba($white, 0.15);
        flex: 1 1 33%;
        padding:0 $gap3;

        &:last-of-type{
          border-right: none;

        }

      }

    }

  }

}
