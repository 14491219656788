section#testimonial {

  text-align: center;

  svg {
    @include size(30);
    fill: $orange;

  }

  blockquote {

    p{
      font-style: italic;
      line-height: 35px;
      margin: 0 0 $gap3 0;

    }

    footer {
      color: $blue;
      text-transform: uppercase;
      letter-spacing: 0.2em;

    }

  }

  @media(min-width: $medium) {


  }

  @media(min-width: $max) {


    blockquote {
      padding: 0 $gap6;

    }

  }

}
