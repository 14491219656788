////////////////////////////////////////////////////////////////////////////////
// Main
////////////////////////////////////////////////////////////////////////////////

site-wrapper {
  background-color: $grey2;

  main {

    .main-bg-image{
      max-width: 100vw;
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;

    }

  }

  @media(min-width: $medium) {

    main {
      background-color: $grey2;
      display: block;
      max-width: 1000px;
      padding: 0 0 $gap8 0;
      position: relative;
      width: 100%;
      z-index: 0;

      .main-bg-image{
        max-width: 1200px;
        top: 0px;
        width:70vw;
        z-index: -1;

      }

    }

  }

}
