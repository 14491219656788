section#tabbed-links {

  @include flex-row();
  background-color: none;
  flex-wrap: wrap;
  margin: 1px 0 0 0;
  padding: 0;

  a {
    flex: 1 1 49%;
    font-size: 14px;
    line-height: 20px;
    margin: 0 1px 1px 0;
    padding: $gap3 $gap5 $gap3 $gap3;
    position: relative;
    text-transform: uppercase;
    transition: background-color 300ms;

    svg {
      @include size(20);
      fill: $white;
      opacity: 0;
      position: absolute;
      right: -10px;
      top: $gap3;
      transition: $transition-timing;


    }

    &:hover {

      svg {
        opacity: 1;
        right: 15px;

      }

    }

  }

  &.orange {

    a {
      background-color: $orange;

      &:hover{
        background-color: $darkOrange;
        color: $white;

      }

    }

  }

  &.blue {

    a {
      background-color: $blue;

      &:hover{
        background-color: $darkBlue;
        color: $white;

      }

    }

  }

  @media(min-width: $medium){

    @include flex-row();
    flex-wrap: nowrap;

    a {
      flex: 1 1 33%;
      margin: 0 1px 0 0;

      &:last-of-type{
        margin: 0;

      }



    }

  }

}
