////////////////////////////////////////////////////////////////////////////////
// Header
////////////////////////////////////////////////////////////////////////////////

$sidebar-width: 230px;

site-wrapper {

  header {
    height: 70px;
    overflow: hidden;
    z-index: 1;

    .fixed{
      background-color: $grey1;

      > a{
        display: block;
        padding: 10px 10px;
        width:250px;

        svg:first-of-type {
          width:220px;

        }

        svg:last-of-type {
          display: none;
          width:130px;

          rect {
            transition: 1000ms;

            &.blues{
              &[data-fill="1"]{ fill:#3EB4DC; }
              &[data-fill="2"]{ fill:#0093CB; }
              &[data-fill="3"]{ fill:#00A3D5; }
              &[data-fill="4"]{ fill:#00AAD7; }

            }

            &.oranges{
              &[data-fill="1"]{ fill:#F6921E; }
              &[data-fill="2"]{ fill:#F9A446; }
              &[data-fill="3"]{ fill:#FAAC58; }
              &[data-fill="4"]{ fill:#F89E3A; }

            }

          }

        }

      }

    }

    mobile-buttons{
      display: block;
      height:70px;
      position: absolute;
      right:0;
      top:0;
      width:122px;

      a{
        background: $grey2;
        display: inline-block;
        height: 70px;
        width: 60px;

        svg{
          @include size(44);
          fill: $purple;
          transform: translate3d(9px, 13px, 0);
        }

        &:first-of-type{
          margin: 0 2px 0 0;

          svg{
            fill: $orange;
            transform: translate3d(9px, 13px, 0) scale(0.7);
          }

        }

      }

    }

  }

  @media(min-width: $medium) {

    header {
      flex: 0 0 $sidebar-width;
      height: auto;
      pointer-events: none;

      .fixed {
        border-right: 1px solid darken($grey1, 5%);
        height: 100vh;
        left: 0;
        overflow: hidden;
        overflow-y: auto;
        padding: $gap6 0 0 $gap7;
        pointer-events: all;
        position: fixed;
        top: 0;
        width: $sidebar-width - 1;

        > a {
          padding: 0;
          width:auto;

          svg:first-of-type {
            display: none;

          }

          svg:last-of-type {
            display: block;
            width:$sidebar-width - 120;
          }

        }

      }

      mobile-buttons{
        display: none;
      }

    }

  }

}


body.menu  {

  header {

    mobile-buttons{
      a.trigger {
        background: $purple;

        svg {
          fill: $white;
        }

      }

    }

  }

}
