////////////////////////////////////////////////////////////////////////////////
// Navigation Bar
////////////////////////////////////////////////////////////////////////////////

navigation-bar {

  nav {

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {

        a {
          text-transform: uppercase;

          svg {
            @include size(16);

          }

        }

        &.phone {

          a {
            padding: 0 0 0 8px;

            svg {
              fill: $orange;
              transform: translate3d(-5px, 2px, 0);

            }

          }

        }

        &.linkedin {

          a {
            padding: 0 0 0 8px;

            svg {
              fill: $blue;
              transform: translate3d(-5px, 1px, 0);

            }

          }

        }

      }



    }

  }

  @media(max-width: $medium - 1px){

    nav {
      position: fixed;
      background: $purple;
      width: 100vw;
      height: calc(100vh - 70px);
      z-index: 9;
      top: 70px;
      opacity: 0;
      pointer-events: none;
      transition: 300ms;

      ul {


        li {
          border-bottom: 1px solid rgba($white, 0.3);

          a {
            color: $white;
            display: block;
            font-size: 20px;
            line-height: 30px;
            padding: $gap2 $gap3;

          }

        }

        & + ul {
          display: none;

        }

      }

    }

  }


  @media(min-width: $medium) {

    nav {
      margin: $gap4 0 0 0;

      ul {
        margin: 0 0 $gap3 0;

        li {

          a {
            color: #999;
            font-size: 12px;
            line-height: 36px;

          }

        }

      }

    }

    copyright-message {
      display: block;
      margin: $gap5 0 0 0;

      img{
        margin: 0 0 $gap2 0;
      }

      p {
        color: #999;
        font-size: 9px;
        line-height: 15px;
        margin: 0 0 $gap 0;
        text-transform: uppercase;

      }

      a{
        color: #999;

        &:hover{
          color: $orange;

        }

      }

    }

  }

}


body.menu  {

  overflow-y: hidden;

  navigation-bar {

    nav {
      opacity: 1;
      pointer-events: all;
    }
  }

}
