body#news{

  &.index{

    content {

      article {
        background-color: $grey1;
        display: block;
        margin: 0 0 $gap2 0;
        padding: $gap3;
        position: relative;
        transition: $transition-timing;

        h3 {
          @extend .bold;
        }

        featured-image{
          display: block;

          height:200px;
          margin: -$gap3;
          margin-bottom: $gap3;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }

        > a {

          svg {
            @include size(40);
            bottom: 20px;
            content: "";
            fill: $purple;
            opacity: 0;
            position: absolute;
            right: -20px;
            transition: $transition-timing;

          }

        }

        &:hover {
          background-color: darken($grey1, 5%);

          a {

            svg {
              opacity: 1;
              right: 20px;

            }

          }

        }

      }

    }

    @media (min-width: $medium) {

      content {
        @include flex-row(stretch, stretch);
        flex-wrap: wrap;

        article {
          margin: 0;

          &:first-of-type {
            flex: 1 1 100%;
            margin: 0 0 $gap3 0;

            a {
              @include flex-row();

              h3{
                margin: 0 0 $gap6 0;

              }

              featured-image{
                flex: 0 0 50%;
                height:auto;
                margin: -$gap3;
                margin-right: $gap3;

              }

            }

          }

        }

      }

    }


    @media(min-width: $medium) and (max-width: $max - 1px){

      content {

        article {

          &:not(:first-of-type){
            flex: 0 1 calc(50% - 12px);
            margin: 0 $gap3 $gap3 0;

          }

          &:not(:first-of-type):nth-of-type(2n + 1){
            margin: 0;

          }

        }

      }

    }

    @media (min-width: $max) {

      content {

        article {

          &:first-of-type{
            margin: 0 0 $gap5 0;
          }

          &:not(:first-of-type){
            flex: 0 1 calc(33% - 30px);
            margin: 0 $gap6 $gap6 0;

          }

          &:not(:first-of-type):nth-of-type(3n + 1){
              margin: 0 0 $gap6 0;

          }

        }

      }

    }

  }


  &._entry{

    content {

      article {
        background-color: $grey1;
        margin: $gap 0;
        padding: $gap;

        featured-image{
          display: block;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          height: 300px;
          margin: -$gap;

        }

      }

      button-group {

        a {

          svg {
            @include size(30);
            fill:$white;
            transform: translateY(2px);

          }

        }

      }

    }

    @media (min-width: $medium) {

      content {

        article {
          margin: $gap3 0;
          padding: $gap3;

          featured-image{
            margin: -$gap3;
            margin-bottom: $gap3;


          }

        }

      }

    }

  }

}
