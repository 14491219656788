section#process-scrollable {

  padding: 0px;

  > div {
    text-align: center;

    h4{
      margin: 0 0 $gap 0;
      font-weight: 600;
      text-transform: uppercase;

    }

    .step-icon {

      span {
        @include size(50);
        background: $orange;
        border-radius: 50%;
        display: inline-block;
        margin: $gap3 0 $gap2 $gap2;
        padding: 10px $gap;
        text-align: center;
        transition: 300ms;

        svg {
          @include size(30);

        }

      }

    }

    .step-body {

      em {
        color: $orange;
        font-style: normal;
        font-weight: bold;
        margin: 0;

      }

      p {
        font-size: 15px;
        line-height: 20px;

      }

      li {
        font-size: 15px;

      }

    }

		&:nth-of-type(2n) {

			.step-icon {

				span {
					background: $blue;

				}

			}

			.step-body {

				em {
					color: $blue;

				}

			}

		}

  }

  @media(min-width: $medium) {

    overflow: hidden;
    padding-top: 50px;
    position: relative;


    &:before {
      content: "";
      background: rgba($white, 0.2);
      height:100%;
      left: 50px;
      position: absolute;
      top: 0;
      width: 1px;

    }

    > div {
      @include flex-row(flex-start, flex-start);
      cursor: pointer;
      margin: 0 0 $gap4 0;
      text-align: left;

      .step-icon {
        flex: 0 0 150px;

        span{
        	@include size(80);
          background: $orange;
          border-radius: 50%;
					border-top-right-radius: 0;
          display: block;
          margin: -30px 0 0 0;
          padding: 18px $gap;
          text-align: center;
          transform: rotate(45deg);
          transition: 300ms;

          svg {
          	@include size(45);
            fill: $white;
            transform: rotate(-45deg);
            transition: 300ms;

          }

        }

      }

      .step-body {

        em {
          color: $orange;
          font-style: normal;
          font-weight: bold;
          margin: 0 $gap 0 0;

        }

        span {
          display: block;
					min-height:80px;
          transition: height $transition-timing, opacity $transition-timing $transition-timing / 2;
          transform: translateY(10px);

        }

      }


    }

  }

}
