body#error{

  main {
    width:100%;
    max-width: 500px;
    margin: 10vh auto;
    text-align: center;

    svg {
      width: 130px;
      margin: 0 0 $gap5 0;

    }

    h3{
      @extend .bold;

    }

  }

}
